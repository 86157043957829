<template>
  <div>
    <template v-if="urlList.length > 0">
      <el-row :gutter="20">
        <el-col :span="24" v-for="(item, index) in urlList" :key="index">
          <el-form-item label-width="125" >
            <label slot="label">{{item.activityRequireName||''}}</label>
            <el-image
              style="width: 100px; height: 100px;"
              v-for="(pitem, pindex) in item.previewList"
              :key="pindex"
              :src="pitem"
              :preview-src-list="item.previewList">
            </el-image>
          </el-form-item>
        </el-col>
      </el-row>
    </template>
    <el-row
      v-else-if="loading">
      <el-col :md="6" :offset="2">
        <el-alert
          title="加载中..."
          :closable="false"
          center
        ></el-alert>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col :md="6" :offset="2">
        <el-alert
          title="暂无截图"
          :closable="false"
          center
        ></el-alert>
      </el-col>
    </el-row>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && Array.isArray(val)) {
          val.map((item) => {
            const v = item;
            v.previewList = [];
            if (Array.isArray(v.pictureList)) {
              v.previewList = v.pictureList.map((m) => (m.urlPathPrefix + m.urlPath));
            }
            return v;
          });
          this.urlList = val;
          console.log(val);
          this.loading = false;
        }
      },
    },
  },
  data() {
    return {
      urlList: [],
      // 预览
      previewList: [],
      // 加载中
      loading: true,
    };
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-form-item__label{
    width: 125px;
    min-height: 32px;
  }
  /deep/ .el-image{
    margin: 0 10px 10px 0;
  }
</style>
