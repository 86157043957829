<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="20" >
        <vxe-table :data="demandList">
          <vxe-table-column field="productLevel" title="产品层级"></vxe-table-column>
          <vxe-table-column field="productCode" title="产品编码"></vxe-table-column>
          <vxe-table-column field="productName" title="产品名称"></vxe-table-column>
        </vxe-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
        if (val && Array.isArray(val)) {
          this.demandList = val;
        }
      },
    },
  },
  data() {
    return {
      // 陈列要求
      demandList: [],
    };
  },
};
</script>
