var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.urlList.length > 0
        ? [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              _vm._l(_vm.urlList, function (item, index) {
                return _c(
                  "el-col",
                  { key: index, attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "125" } },
                      [
                        _c(
                          "label",
                          { attrs: { slot: "label" }, slot: "label" },
                          [_vm._v(_vm._s(item.activityRequireName || ""))]
                        ),
                        _vm._l(item.previewList, function (pitem, pindex) {
                          return _c("el-image", {
                            key: pindex,
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: pitem,
                              "preview-src-list": item.previewList,
                            },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : _vm.loading
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 6, offset: 2 } },
                [
                  _c("el-alert", {
                    attrs: { title: "加载中...", closable: false, center: "" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 6, offset: 2 } },
                [
                  _c("el-alert", {
                    attrs: { title: "暂无截图", closable: false, center: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }