<script>
import Form, { formCreate } from '../../../../../components/form';
import ExecuteResult from './component/execute_result';
import DesplayDemand from './component/display_demand';
import request from '../../../../../utils/request';

formCreate.component('executeResult', ExecuteResult);
formCreate.component('displayDemand', DesplayDemand);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    // 获取详情
    async getDetail() {
      const requestUrl = `/sfa/sfaVisitStepActivityExecutionController/findVisitStepCostActivityExecutionDetailEs?id=${this.formConfig.row.id}`;
      const { row } = this.formConfig; // 表格行数据
      const param = {
        // activityExecutionId: row.activityExecutionId, // 活动执行Id
        // bizId: row.id, // 活动执行Id
        // activityType: 'cost', // 费用活动
        // stepCode: 'sfa_step_code_cost',
        // clientType: row.clientType,
        // visitPlanInfoId: row.visitPlanInfoId,
      };
      const { result } = await request.post(requestUrl, param);

      // 活动要求
      const activityRequireName = result.activityRequireReqVoList.map((item) => item.activityRequireName);
      // 执行结果
      // const executeResultList = [];
      // result.activityRequireReqVoList.forEach((item) => {
      //   item.pictureList.forEach((pictureItem) => {
      //     executeResultList.push(pictureItem.urlPathPrefix + pictureItem.urlPath);
      //   });
      // });
      const executeResultList = result.activityRequireReqVoList || [];
      console.log(result);
      result.activityType = await this.getDictValue(result.activityType); // 翻译活动类型
      this.setValue(row); // 基本信息
      this.setValue(result); // 活动信息
      this.setValue({
        activityRequireName: activityRequireName.join('、'), // 活动要求
        executeResult: executeResultList, // 执行结果
      });
    },
    // 获取数据字典
    async getDictValue(dictCode) {
      const requestUrl = '/mdm/mdmdictdata/list';
      const { result } = await request.post(requestUrl, { dictCode, dictTypeCode: 'sfa_activity_type' });
      const { dictValue } = result.find((item) => item.dictCode === dictCode);
      return dictValue; // 字典值
    },
  },
  async created() {
    const rule = await this.getFormRule('sfaCenter_visitManage_activityExecution_form');
    // rule.forEach((item) => {
    //   const v = item;
    //   v.props = {
    //     ...item.props,
    //     disabled: false,
    //     readonly: true, // 只读
    //   };
    // });
    // 获取详情
    this.getDetail();
  },
};
</script>
