var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "vxe-table",
                { attrs: { data: _vm.demandList } },
                [
                  _c("vxe-table-column", {
                    attrs: { field: "productLevel", title: "产品层级" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "productCode", title: "产品编码" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "productName", title: "产品名称" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }